import MessageEnum, {
    getName
  } from '../../../../framework/src/Messages/MessageEnum';


export enum NewMessageConstants {
    updateTemplate,
    updateTemplateData,
    reGenerateGuestToken,
    updateNotification,
    NotificationCount,
    updateSmsIntegration,
    updateSmsIntegrationData,  
}


export const MessageConstants = {
    ...MessageEnum,
    ...NewMessageConstants
}
export const getMessageName = (myEnum: NewMessageConstants) => {
    return MessageConstants[myEnum];
};